import { useDispatch, useSelector } from "react-redux";
import {
  LdsSearch,
  useToastContext,
  LdsLink,
  LdsIcon,
  LdsButton,
} from "@elilillyco/ux-lds-react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CLoader from "../../components/shared/CLoader";
import SpecialtyTable from "../../components/specialties/SpecialtyTable";

import {
  UPDATE_SPECIALTY,
  FETCH_SPECIALTIES,
  FILTER_SPECIALTIES,
  RESET_DATA,
  SET_SEARCH_TEXT,
} from "../../store/specialties";

import infoToastConfig from "../../components/shared/Toast";

import "./../../assets/scss/admin/specialties.scss";

const Specialties = () => {
  const dispatch = useDispatch();

  const { loading, specialties, searchText } = useSelector(
    ({ specialties }) => specialties
  );
  const { uuid } = useSelector(({ user }) => user);

  const { addToast } = useToastContext();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const isAddPage = pathname.includes("add-specialties");

  const isAdminPage = pathname.includes("/admin/");

  const handleSearch = (text) => {
    dispatch(FILTER_SPECIALTIES(text));
  };

  const updateSpecialty = async (specialtyUUID, isLillySpecialty) => {
    const delPayload = {
      specialties: [
        {
          uuid: specialtyUUID,
          lilly_specialty: isLillySpecialty,
        },
      ],
      updated_by: uuid,
    };
    const { meta } = await dispatch(UPDATE_SPECIALTY(delPayload));
    if (meta.requestStatus === "fulfilled") {
      dispatch(FETCH_SPECIALTIES(isAddPage));
      addToast(
        infoToastConfig("Your changes have been saved successfully.", "success")
      );
    }
  };

  const navigateToAddSpecialty = () => {
    if (isAdminPage) {
      navigate("/admin/add-specialties");
    } else {
      navigate("/add-specialties");
    }
  };

  useEffect(() => {
    dispatch(FETCH_SPECIALTIES(isAddPage));
    return () => dispatch(RESET_DATA());
  }, []);

  return (
    <section className="section">
      <div className="wrapper overview-page">
        <div className="mt-3">
          <div>
            {isAddPage && (
              <div>
                <LdsLink
                  href={isAdminPage ? "/admin/specialties" : "/specialties"}
                >
                  <LdsIcon decorative name="CaretLeft" inline />
                  Back to Specialties
                </LdsLink>
              </div>
            )}
            <div className="row">
              <div className="col">
                <div>
                  <div>
                    <div className="row">
                      {isAddPage ? (
                        <div className="col-8 mt-4">
                          <h2>Add Specialties</h2>
                        </div>
                      ) : (
                        <>
                          <div className="col-8">
                            <h2>Specialties</h2>
                          </div>
                          <div className="col-4 create-specialty">
                            <LdsButton
                              classes="button-icon-left blue-btn"
                              icon="Plus"
                              iconPosition="before"
                              onClick={navigateToAddSpecialty}
                            >
                              Add Specialty
                            </LdsButton>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3">
          <div className="col-6 mb-4 pl-0">
            <LdsSearch
              name="search_keyword"
              value={searchText}
              onChange={(text) => dispatch(SET_SEARCH_TEXT(text))}
              onSubmit={handleSearch}
            />
          </div>
          {loading ? (
            <CLoader size={90} />
          ) : (
            <div className="content-block">
              <SpecialtyTable
                specialties={specialties}
                isAddPage={isAddPage}
                updateSpecialty={updateSpecialty}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Specialties;
