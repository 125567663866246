import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_FILTER_LIST,
  FETCH_APPROVED_SPECIALTIES,
} from "../store/approvedSpecialties";
import ApprovedSpecialtiesTable from "../components/approvedSpecialties/ApprovedSpecialtiesTable";
import CLoader from "../components/shared/CLoader";
import { LdsButton, LdsSearch, LdsSelect } from "@elilillyco/ux-lds-react";
import "../assets/scss/apprSpecialties/approvedSpecialties.scss";
const ApprovedSpec = () => {
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedIndication, setSelectedIndication] = useState("");
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [searchText, setSearchText] = useState("");
  const [firstRender, setFirstRender] = useState(true);

  const getTableData = (searchValue) => {
    return {
      brand: selectedBrand === null ? "" : selectedBrand,
      indication: selectedIndication === null ? "" : selectedIndication,
      quarter: selectedQuarter === null ? "" : selectedQuarter,
      department: selectedDepartment === null ? "" : selectedDepartment,
      searchKeyword: searchValue === undefined ? searchText : searchValue,
    };
  };

  const dispatch = useDispatch();
  const {
    filterBrand,
    filterIndication,
    filterQuarter,
    filterDepartment,
    loading,
  } = useSelector(({ approvedSpecialties }) => approvedSpecialties);

  const getOptions = (options) => {
    const newOptions = options.map((option) => ({
      value: option,
      label: option,
    }));
    const emptyOption = { label: "Select", value: "" };
    newOptions.unshift(emptyOption);
    return newOptions;
  };

  useEffect(() => {
    dispatch(FETCH_FILTER_LIST());
    dispatch(FETCH_APPROVED_SPECIALTIES(getTableData()));
    setFirstRender(false);
  }, []);

  useEffect(() => {
    if (!firstRender) {
      dispatch(FETCH_APPROVED_SPECIALTIES(getTableData()));
    }
  }, [selectedBrand, selectedIndication, selectedQuarter, selectedDepartment]);

  const submitSearch = () => {
    dispatch(FETCH_APPROVED_SPECIALTIES(getTableData()));
    // setCurrentPage(1);
  };

  const clearAllFilters = () => {
    setSelectedBrand("");
    setSelectedIndication("");
    setSelectedQuarter("");
    setSelectedDepartment("");
    setSearchText("");

    // fetching data here if only search field was set else fetching it in useEffect
    if (
      selectedBrand === "" &&
      selectedIndication === "" &&
      selectedQuarter === "" &&
      selectedDepartment === "" &&
      searchText !== ""
    ) {
      dispatch(FETCH_APPROVED_SPECIALTIES(getTableData("")));
    }
  };

  return (
    <>
      <section className="section">
        <div className="approved-page">
          <div className="mt-3">
            <div className="row">
              <h2 className="pl-4">Approved Specialties</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <LdsSearch
                id={"searchSpecialty"}
                onChange={setSearchText}
                value={searchText}
                onSubmit={submitSearch}
              />
            </div>
            <div className="col-6">
              <LdsButton onClick={clearAllFilters}>Clear All</LdsButton>
            </div>
          </div>
          {filterBrand.length > 0 &&
            filterDepartment.length > 0 &&
            filterQuarter.length > 0 &&
            filterIndication.length > 0 && (
              <div className="row filter">
                <div className="col-2">
                  <LdsSelect
                    id="select-product"
                    name="select-product"
                    label="Product"
                    value={selectedBrand}
                    options={getOptions(filterBrand)}
                    onChange={(option) => {
                      setSelectedBrand(option.value);
                    }}
                  ></LdsSelect>
                </div>
                <div className="col-4">
                  <LdsSelect
                    id="select-indication"
                    name="select-indication"
                    label="Indication"
                    value={selectedIndication}
                    options={getOptions(filterIndication)}
                    onChange={(option) => setSelectedIndication(option.value)}
                  ></LdsSelect>
                </div>
                <div className="col-2">
                  <LdsSelect
                    id="select-quarter"
                    name="select-quarter"
                    label="Quarter"
                    value={selectedQuarter}
                    options={getOptions(filterQuarter)}
                    onChange={(option) => setSelectedQuarter(option.value)}
                  ></LdsSelect>
                </div>
                <div className="col-2">
                  <LdsSelect
                    id="select-department"
                    name="select-department"
                    label="Department"
                    value={selectedDepartment}
                    options={getOptions(filterDepartment)}
                    onChange={(option) => setSelectedDepartment(option.value)}
                  ></LdsSelect>
                </div>
              </div>
            )}
          {loading ? (
            <CLoader size={90} />
          ) : (
            <div className="apprSpecTable">
              <ApprovedSpecialtiesTable />
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ApprovedSpec;
