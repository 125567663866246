import { Outlet, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import roleMapping from "../data/roleMapping";
import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import {
  LdsHeader,
  LdsSticky,
  LdsLillyLogo,
  LdsSelect,
  useToastContext,
} from "@elilillyco/ux-lds-react";
import { useNavigate, useLocation, matchPath } from "react-router-dom";
import { SideNavBar } from "./SideNavBar";
import infoToastConfig from "../components/shared/Toast";

import { REMOVE_ERROR } from "../store/error";

import "./../assets/scss/shared/topNavbar.css";

const TopNavBar = () => {
  const { instance } = useMsal();
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { loader, name, roles } = useSelector(({ user }) => user);
  const { error } = useSelector(({ error }) => error);

  const { addToast } = useToastContext();

  useEffect(() => {
    if (error) {
      addToast(infoToastConfig(error, "error", 2500));
    }
    return () => {
      dispatch(REMOVE_ERROR());
    };
  }, [error]);

  const logout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };
  const render = () => {
    let links = [
      <div key="name">
        <span>{name}</span>
      </div>,
      <div key="role">
        {!loader && (
          <LdsSelect
            id="roleselect"
            name="roleselect"
            options={roles?.map((option) => ({
              value: option,
              label: roleMapping[option],
            }))}
            value={sessionStorage.getItem("role")}
            onChange={(option) => {
              sessionStorage.setItem("role", option.value);
              if (option.value === "spclty_hub_bia_team") {
                return navigate("/specialties");
              }
              navigate("/overview");

              // refersh page if already on overview page
              if (location.pathname.includes("overview")) {
                navigate(0);
              }
            }}
          />
        )}
      </div>,

      <div key="appr-spec">
        <NavLink className="nav-link" to="/approvedspecialities">
          Approved Specialities
        </NavLink>
      </div>,

      <div key="logout">
        <span className="nav-link" onClick={logout}>
          Logout
        </span>
      </div>,
    ];

    if (sessionStorage.getItem("role") === "spclty_hub_admin") {
      links.splice(
        3,
        0,
        <NavLink className="nav-link" to="/admin/users" key="admin">
          Admin
        </NavLink>
      );
    } else if (sessionStorage.getItem("role") === "spclty_hub_super_admin") {
      links.splice(
        3,
        0,
        <NavLink className="nav-link" to="/admin/users" key="super-admin">
          SuperAdmin
        </NavLink>
      );
    }
    return links;
  };

  const navfn = () => {
    return (
      <>
        <LdsSticky>
          <LdsHeader
            childMobilePadding="10px"
            customLogo={
              <LdsLillyLogo size="md" paddingOverrideClass="pd-0" link="/" />
            }
          >
            {render()}
          </LdsHeader>
        </LdsSticky>
        <Outlet />
      </>
    );
  };
  if (
    matchPath("/admin/*", location.pathname) ||
    matchPath("/superadmin/*", location.pathname)
  ) {
    return (
      <>
        <SideNavBar currentRoute={location.pathname}>{navfn()}</SideNavBar>
      </>
    );
  } else {
    return navfn();
  }
};

export default TopNavBar;
