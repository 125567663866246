import { useEffect, useState } from "react";
import {
  LdsButton,
  useToastContext,
  LdsSearch,
} from "@elilillyco/ux-lds-react";
import { useDispatch, useSelector } from "react-redux";

import {
  UPDATE_USER_LIST,
  FETCH_USER_LIST,
  FETCH_BRAND_LIST,
  FETCH_INCENTIVIZED_JOB,
} from "../../store/admin";

import CLoader from "../../components/shared/CLoader";
import UsersTable from "../../components/admin/UsersTable";
import infoToastConfig from "../../components/shared/Toast";

const Users = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FETCH_USER_LIST());
    dispatch(FETCH_BRAND_LIST());
  }, []);

  const { loading } = useSelector(({ admin }) => admin);
  const { uuid } = useSelector(({ user }) => user.user || {});

  const { addToast } = useToastContext();

  const [searchText, setsearchText] = useState("");

  const saveUserListForm = async (obj) => {
    if (Array.isArray(obj)) {
      obj.forEach((object) => {
        delete object["isSelected"];
        delete object["isVisible"];
      });
    } else {
      delete obj.isVisible;
      delete obj.isSelected;
    }
    const updateUserDetails = {
      users: Array.isArray(obj) ? obj : [obj],
      updated_by: uuid,
    };
    return await dispatch(UPDATE_USER_LIST(updateUserDetails));
  };

  const checkSuperAdmin = () => {
    if (sessionStorage.getItem("role") === "spclty_hub_super_admin") {
      return true;
    }
    return false;
  };

  const listjobtrigger = async () => {
    const { meta } = await dispatch(
      FETCH_INCENTIVIZED_JOB({
        created_by: uuid,
      })
    );
    if (meta.requestStatus === "fulfilled") {
      addToast(infoToastConfig("Lists Created Successfully.", "success"));
    }
  };

  return (
    <section className="section">
      <div className="wrapper overview-page">
        <div className="mt-3">
          <div className="row">
            <div className="col-12 mt-4 pl-4">
              <h2>Users</h2>
            </div>
          </div>
          <div className="row mb-4 pl-1">
            <div className="col-6">
              <LdsSearch
                onChange={setsearchText}
                value={searchText}
              ></LdsSearch>
            </div>
            <div className="col-6">
              {checkSuperAdmin() && (
                <LdsButton
                  classes="button-icon-left blue-btn right"
                  onClick={listjobtrigger}
                >
                  Incentivized List
                </LdsButton>
              )}
            </div>
          </div>
          <div className="mt-3">
            {loading && <CLoader size={90} />}
            {!loading && (
              <UsersTable
                saveUserListForm={saveUserListForm}
                searchText={searchText}
              ></UsersTable>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Users;
