import { useState, useEffect } from "react";
import moment from "moment";
import { LdsPagination, LdsLink, LdsCard } from "@elilillyco/ux-lds-react";

import SortableHeader from "../shared/SortableHeader";

import "../../assets/scss/apprSpecialties/approvedSpecialtiesTable.scss";
import { useSelector } from "react-redux";

const ApprovedSpecialtiesTable = () => {
  const { approvedSpecialties } = useSelector(
    ({ approvedSpecialties }) => approvedSpecialties
  );

  const [visibleSpecialties, setVisibleSpecialties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 10;
  const totalPages = Math.ceil(visibleSpecialties.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const convertDateFormat = (dateObj) => {
    return dateObj ? moment(dateObj).format("MMMM DD, YYYY") : "";
  };

  const tableHead = [
    "Title",
    "Product",
    "Indication",
    "Quarter",
    "Department",
    "Approved Date",
  ];

  useEffect(() => {
    const tempSpecialties = JSON.parse(JSON.stringify(approvedSpecialties));
    if (tempSpecialties.length) {
      tempSpecialties.forEach((specialty) => {
        if (specialty.department_field === "RETAIL") {
          specialty.department_field = "COMMERCIAL";
        }
      });
    }
    setVisibleSpecialties(tempSpecialties);
  }, [approvedSpecialties]);

  return (
    <>
      {visibleSpecialties.length > 0 ? (
        <>
          <LdsCard>
            <LdsCard.Body>
              <table
                id="approved-speciality-list-table"
                className="lds-table flat"
              >
                <thead>
                  <tr>
                    <SortableHeader
                      headAlign={"align-left b"}
                      header={tableHead}
                      rowData={visibleSpecialties}
                      rowKeyName={[
                        "list_title",
                        "brand",
                        "indication",
                        "list_quarter",
                        "department_field",
                        "updated_date",
                      ]}
                      sortedRowData={setVisibleSpecialties}
                    />
                  </tr>
                </thead>
                <tbody>
                  {visibleSpecialties.map(
                    (itm, index) =>
                      index >= indexOfFirstItem &&
                      index < indexOfLastItem && (
                        <>
                          <tr key={itm.uuid}>
                            <td>
                              <LdsLink href={`/details/${itm.uuid}`}>
                                {itm.list_title}
                              </LdsLink>
                            </td>
                            <td>{itm.brand}</td>
                            <td className="center">{itm.indication}</td>
                            <td>{itm.list_quarter}</td>
                            <td>{itm.department_field}</td>
                            <td>{convertDateFormat(itm.updated_date)}</td>
                          </tr>
                        </>
                      )
                  )}
                </tbody>
              </table>
              <div className="row">
                <div>
                  <LdsPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                  ></LdsPagination>
                </div>
              </div>
            </LdsCard.Body>
          </LdsCard>
        </>
      ) : (
        <div className="row">
          <span className="center">
            <h2>No Record Found</h2>
          </span>
        </div>
      )}
    </>
  );
};
export default ApprovedSpecialtiesTable;
