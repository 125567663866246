import { useEffect, useState } from "react";
import {
  LdsCard,
  LdsPagination,
  LdsTable,
  LdsIcon,
  useLdsModal,
  LdsCheckbox,
} from "@elilillyco/ux-lds-react";
import { useDispatch, useSelector } from "react-redux";

import DeleteSpecialtyModal from "./DeleteSpecialtyModal";

import { SET_CURRENT_PAGE } from "../../store/specialties";
import SortableHeader from "../shared/SortableHeader";

const SpecialtyTable = ({ specialties, isAddPage, updateSpecialty }) => {
  const specialtyTableHeader = [
    "Specialty Code",
    "Description",
    "Specialty Type",
  ];

  const dispatch = useDispatch();

  const { currentPage, totalPages, start, end } = useSelector(
    ({ specialties }) => specialties
  );

  const [delUUID, setDelUUID] = useState("");
  const [visibleSpecialties, setVisibleSpecialties] = useState([]);

  const { isModalOpen, setIsModalOpen } = useLdsModal();

  const isViewerRole = () =>
    sessionStorage.getItem("role") === "spclty_hub_viewer";

  const checkListLen = () =>
    visibleSpecialties.filter((o) => o.show).length > 0;

  const setCurrentPage = (pageNo) => {
    dispatch(SET_CURRENT_PAGE(pageNo));
  };

  const handleCheckbox = (e, specialtyUUID) => {
    const checked = e.target.checked;
    if (checked) {
      updateSpecialty(specialtyUUID, true);
    }
  };

  useEffect(() => {
    if (delUUID) {
      setIsModalOpen(true);
    }
  }, [delUUID]);

  useEffect(() => {
    if (!isModalOpen) {
      setDelUUID("");
    }
  }, [isModalOpen]);

  useEffect(() => {
    setVisibleSpecialties(specialties);
  }, [specialties]);

  return (
    <div>
      <LdsCard classes="specialties-card">
        <LdsCard.Body>
          {checkListLen() ? (
            <div className="mt-3">
              <LdsTable
                id="speciality-list-table"
                flat
                thead="specialtyTableHeader"
              >
                <thead>
                  <tr className="align-left">
                    <SortableHeader
                      headAlign={"align-left b"}
                      header={specialtyTableHeader}
                      rowData={visibleSpecialties}
                      rowKeyName={[
                        "specialty",
                        "description",
                        "specialty_type",
                      ]}
                      sortedRowData={setVisibleSpecialties}
                    />
                  </tr>
                </thead>
                <tbody>
                  {visibleSpecialties
                    .filter((o) => o.show)
                    .map(
                      (specialty, index) =>
                        index >= start &&
                        index < end &&
                        specialty.show && (
                          <tr key={index} className="align-left">
                            <td>
                              {isAddPage && !isViewerRole() && (
                                <LdsCheckbox
                                  onClick={(e) =>
                                    handleCheckbox(e, specialty.uuid)
                                  }
                                />
                              )}
                              {specialty.specialty}
                            </td>
                            <td>{specialty.description}</td>
                            <td>{specialty.specialty_type}</td>
                            {!isAddPage && !isViewerRole() && (
                              <td className="i-action delete-sp">
                                <span
                                  onClick={() => setDelUUID(specialty.uuid)}
                                >
                                  <LdsIcon
                                    name="TrashSimpleFill"
                                    label="Delete Speciality list"
                                    description="Delete the speciality list.."
                                    inline
                                  />
                                </span>
                              </td>
                            )}
                          </tr>
                        )
                    )}
                </tbody>
              </LdsTable>
              <br />
              <LdsPagination
                id="pageID"
                className="floatRight"
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageRangeVisible={5}
                totalPages={totalPages}
              />
              <br />
            </div>
          ) : (
            <p className="mt-3">No Record Found</p>
          )}
        </LdsCard.Body>
      </LdsCard>
      {isModalOpen && (
        <DeleteSpecialtyModal
          open={isModalOpen}
          delUUID={delUUID}
          setModalOpen={setIsModalOpen}
          updateSpecialty={updateSpecialty}
        />
      )}
    </div>
  );
};

export default SpecialtyTable;
