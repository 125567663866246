import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/scss/details/listApprovedModal.scss";
import {
  LdsButton,
  LdsModal,
  LdsTable,
  LdsPagination,
  LdsSearch,
  LdsCheckbox,
  LdsLoadingSpinner,
  LdsIcon,
} from "@elilillyco/ux-lds-react";
const ListApprovedModal = ({
  isListAppModalOpen,
  setListAppModalOpen,
  msg,
  setMsg,
  updateSpecialityDetails,
  addedSpecialtyCodes,
  uncommonRespCodes,
  delSpecialtyCodes,
}) => {
  const thead = [
    "Specialty Code",
    "Specialty Description",
    "Incentivized",
    "Responsibility Code",
    "Status",
  ];
  const theadSf = ["Specialty Code", "Specialty Description", "Status"];
  const [allChanges, setAllChanges] = useState([]);
  const returnOverview = () => {
    setListAppModalOpen(false);
  };
  useEffect(() => {
    if (
      (delSpecialtyCodes.length > 0 &&
        msg === "Are you sure want to approve ?") ||
      (addedSpecialtyCodes.length > 0 &&
        msg === "Are you sure want to approve ?") ||
      (uncommonRespCodes.length > 0 && msg === "Are you sure want to approve ?")
    ) {
      setAllChanges([
        ...addedSpecialtyCodes,
        ...uncommonRespCodes,
        ...delSpecialtyCodes,
      ]);
    }
  }, [delSpecialtyCodes, addedSpecialtyCodes, uncommonRespCodes]);
  const confirmSubmission = () => {
    updateSpecialityDetails();
  };
  const showTable = () => {
    if (
      (delSpecialtyCodes.length > 0 &&
        msg === "Are you sure want to approve ?") ||
      (addedSpecialtyCodes.length > 0 &&
        msg === "Are you sure want to approve ?") ||
      (uncommonRespCodes.length > 0 && msg === "Are you sure want to approve ?")
    ) {
      return true;
    }
    return false;
  };
  const getTableColumn = () => {
    if (sessionStorage.getItem("role") === "spclty_hub_mlr_approver") {
      return theadSf;
    }
    return thead;
  };
  const checkIncenApproverRole = () => {
    if (sessionStorage.getItem("role") === "spclty_hub_incentivized_approver") {
      return true;
    }
    return false;
  };
  const noUpdates = () => {
    if (msg === "Are you sure want to approve ?" && allChanges.length === 0) {
      return true;
    }
    return false;
  };
  return (
    <>
      <LdsModal
        modalId="list-app-modal"
        open={isListAppModalOpen}
        setModalOpen={setListAppModalOpen}
        // heading="Add Specialty"
      >
        <div>
          <div class="icon-size">
            <LdsIcon
              name="CheckCircleFill"
              style={{ fill: "#31855b", maxWidth: "60px", maxHeight: "60px" }}
            />
          </div>
          {/* <h1>Inside List Approved Modal</h1> */}
          <div class="msg-data">
            <h2>{msg}</h2>
          </div>
          {showTable() ? (
            <div>
              <LdsTable
                id="speciality-list-table"
                flat
                sortable
                className="stl-table-list"
                layoutClass="layout-auto"
              >
                <thead>
                  <tr>
                    {getTableColumn().map((itm, index) => (
                      <th>{itm}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {allChanges.map((table) => (
                    <tr className={table.status}>
                      <td>{table.specialty_code}</td>
                      <td>{table.specialty_description}</td>
                      {checkIncenApproverRole() ? (
                        <td>{table.incentivized ? "Yes" : "No"}</td>
                      ) : (
                        <></>
                      )}
                      {checkIncenApproverRole() ? (
                        <td>{table.responsibility_code}</td>
                      ) : (
                        <></>
                      )}
                      <td>{table.status}</td>
                    </tr>
                  ))}
                </tbody>
              </LdsTable>
            </div>
          ) : (
            <></>
          )}
          {noUpdates() ? (
            <div className="mrgn">
              {" "}
              <h3>No updates were made to existing list.</h3>
            </div>
          ) : (
            <></>
          )}
          <div className="row">
            <div className="col">
              <span className="floatRight mt-4">
                <span className="mr-4">
                  <LdsButton
                    // icon="Plus"
                    // iconPosition="before"
                    classes="button-icon-left blue-bdr-btn"
                    onClick={returnOverview}
                  >
                    No
                  </LdsButton>
                </span>
                <LdsButton
                  // icon="Plus"
                  // iconPosition="before"
                  classes="button-icon-left blue-btn"
                  onClick={confirmSubmission}
                >
                  Yes
                </LdsButton>
              </span>
            </div>
          </div>
        </div>
      </LdsModal>
    </>
  );
};
export default ListApprovedModal;
