import {
  LdsModal,
  LdsButton,
  LdsTextField,
  LdsSelect,
  LdsScrollingCheckboxGroup,
} from "@elilillyco/ux-lds-react";
import "./../../assets/scss/details/deleteSpecModal.scss";
import roleMapping from "../../data/roleMapping";
import { useEffect, useState } from "react";
const EditUserDetailsModal = ({
  isEditUserDetailsOpen,
  setEditUserDetailsOpen,
  userDataObj,
  brandListData,
  saveForm,
}) => {
  const roledropdown = Object.keys(roleMapping).map((key) => {
    return { lable: roleMapping[key], value: key };
  });
  const options = [
    {
      label: "Admin",
      value: "spclty_hub_admin",
    },
    {
      label: "MLR Approver",
      value: "spclty_hub_mlr_approver",
    },
    {
      label: "HCP SF Marketer",
      value: "spclty_hub_hcp_sf_marketer",
    },
    {
      label: "HCP Marketer",
      value: "spclty_hub_hcp_marketer",
    },
    {
      label: "Omnichannel",
      value: "spclty_hub_omnichannel_advisor",
    },
    {
      label: "BIA",
      value: "spclty_hub_bia_team",
    },
    {
      label: "Incentivized Approver",
      value: "spclty_hub_incentivized_approver",
    },
    {
      label: "Viewer",
      value: "spclty_hub_viewer",
    },
    {
      label: "MLR Reviewer",
      value: "spclty_hub_mlr_reviewer",
    },
    {
      label: "Super Admin",
      value: "spclty_hub_super_admin",
    },
  ];
  const [selcrole, setselcrole] = useState(userDataObj.role);
  const [selectedBrands, setSelectedBrands] = useState([]);
  useEffect(() => {
    setSelectedBrands(userDataObj.brand || []);
  }, [userDataObj, isEditUserDetailsOpen]);
  const handleSelectDeselectAll = () => {
    if (brandListData?.length === selectedBrands?.length) {
      handleBrandChange([]);
    } else {
      handleBrandChange(brandListData.map(({ value }) => value));
    }
  };
  const handleBrandChange = (brands) => {
    setSelectedBrands(brands);
  };
  const handleUserDetails = () => {
    const draft = JSON.parse(JSON.stringify(userDataObj));
    draft.brand = selectedBrands.filter((item) => item !== "ALL");
    draft.role = selcrole;

    if (
      selcrole &&
      (selcrole === "spclty_hub_admin" ||
        selcrole === "spclty_hub_viewer" ||
        selcrole === "spclty_hub_super_admin")
    ) {
      draft.brand = [];
      draft.brand.push("ALL");
    }

    if (selcrole && selcrole === "spclty_hub_bia_team") {
      draft.brand = [];
    }
    setEditUserDetailsOpen(false);
    saveForm(draft);
    // this.$emit("saveForm", draft);
  };
  const checkRole = (obj) => {
    if (
      obj &&
      (obj.role === "spclty_hub_admin" ||
        obj.role === "spclty_hub_bia_team" ||
        obj.role === "spclty_hub_viewer")
    ) {
      return false;
    }

    return true;
  };
  return (
    <>
      <LdsModal
        modalId="EditUserDetailsModal"
        open={isEditUserDetailsOpen}
        setModalOpen={setEditUserDetailsOpen}
      >
        {true && (
          <>
            {" "}
            <div>
              <h2 className="ta-center">Edit User Details</h2>
            </div>
            <div>
              <div className="row">
                <div className="col-4">
                  <LdsTextField
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    className="textfieldstyle"
                    labelClassName="labelfieldstyle"
                    defaultValue={userDataObj.first_name}
                    required={false}
                    disabled
                  />
                </div>
                <div className="col-4">
                  <LdsTextField
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    className="textfieldstyle"
                    labelClassName="labelfieldstyle"
                    defaultValue={userDataObj.last_name}
                    required={false}
                    disabled
                  />
                </div>
                <div className="col-4">
                  <LdsTextField
                    id="emailId"
                    name="emailId"
                    label="Email Id"
                    className="textfieldstyle"
                    labelClassName="labelfieldstyle"
                    defaultValue={userDataObj.email_id}
                    required={false}
                    disabled
                  />
                </div>
              </div>
              <div className="row my-4">
                <div className="col-4">
                  <LdsTextField
                    id="status"
                    name="status"
                    label="Status"
                    className="textfieldstyle"
                    labelClassName="labelfieldstyle"
                    defaultValue={userDataObj.active ? "Active" : "In Active"}
                    required={false}
                    disabled
                  />
                </div>
                <div className="col-4">
                  <LdsSelect
                    id="defaultSelectRole"
                    label="Select Role"
                    name="defaultSelectRole"
                    options={options}
                    value={userDataObj.role}
                    onChange={(option) => {
                      setselcrole(option.value);
                    }}
                  />
                </div>
                <div className="col-4">
                  {checkRole(userDataObj) && (
                    <LdsScrollingCheckboxGroup
                      label={
                        <>
                          Brand(s)
                          <LdsButton
                            linkButton
                            onClick={handleSelectDeselectAll}
                          >
                            {brandListData?.length === selectedBrands?.length
                              ? "Deselect"
                              : "Select"}
                            All
                          </LdsButton>
                        </>
                      }
                      labelId="brandSelect"
                      options={brandListData}
                      values={selectedBrands}
                      setValues={handleBrandChange}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-end">
              <LdsButton
                iconPosition="before"
                className="blue-bdr-btn"
                onClick={() => {
                  setEditUserDetailsOpen(false);
                }}
              >
                Cancel
              </LdsButton>
              <LdsButton
                iconPosition="before"
                className="blue-btn ml-2"
                onClick={() => {
                  handleUserDetails();
                }}
              >
                Add
              </LdsButton>
            </div>
          </>
        )}
      </LdsModal>
    </>
  );
};
export default EditUserDetailsModal;
