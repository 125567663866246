import { useEffect, useState } from "react";
import {
  LdsButton,
  LdsSelect,
  LdsCard,
  useLdsModal,
  useToastContext,
  LdsToast,
  LdsBadge,
} from "@elilillyco/ux-lds-react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  CreateListModal,
  CreateNewListModal,
  OverviewTile,
} from "../components/overview";

import {
  FETCH_OVERVIEW,
  SET_BRAND,
  FETCH_EXPORT_SPECIALTY_LIST,
  SET_LOADING,
} from "../store/overview";

import "./../assets/scss/overview/overview.scss";
import CLoader from "../components/shared/CLoader";
import infoToastConfig from "../components/shared/Toast";

const Overview = () => {
  const filtersArray = ["SALESFORCE", "MLR", "APPROVED", "DRAFT"];

  const [userBrands, setUserBrands] = useState([]);
  const [isExistingCreateList, setIsExistingCreateList] = useState("");
  const [dropdownError, setDropdownError] = useState(false);
  const [filters, setFilters] = useState(() =>
    filtersArray.reduce((acc, filter) => {
      return { ...acc, filter: false };
    })
  );

  const dispatch = useDispatch();

  const { brand, uuid } = useSelector(({ user }) => user.user || {});
  const { current, historical, loading, selectedBrand } = useSelector(
    ({ overview }) => overview
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const { addToast } = useToastContext();

  const {
    isModalOpen: isCreateNewListModalOpen,
    setIsModalOpen: setIsCreateNewListModalOpen,
  } = useLdsModal();

  const {
    isModalOpen: isCreateListModalOpen,
    setIsModalOpen: setIsCreateListModalOpen,
  } = useLdsModal();

  const {
    isModalOpen: isDeleteModalOpen,
    setIsModalOpen: setIsDeleteModalOpen,
  } = useLdsModal();

  /**
   * Checks if user has access to multiple brands or not
   *
   * @returns true if user has access to multiple brands
   */
  const hasAccessToMultipleBrands = () => userBrands.length > 1;

  /**
   * Checks if current logged in user is admin or super admin
   *
   * @returns true if user is admin or super admin else false
   */
  const isAdminRole = () =>
    sessionStorage.getItem("role") === "spclty_hub_admin" ||
    sessionStorage.getItem("role") === "spclty_hub_super_admin";

  /**
   * Checks if current logged in user is viewer
   *
   * @returns true if user is viewer else false
   */
  const isViewerRole = () =>
    sessionStorage.getItem("role") === "spclty_hub_viewer";

  const openCreateNewListModal = () => {
    setIsCreateNewListModalOpen(true);
  };

  const openCreateListModal = (modalType) => {
    if (modalType && modalType === "existing") {
      setIsExistingCreateList(true);
    } else {
      setIsExistingCreateList(false);
    }
    setIsCreateNewListModalOpen(false);
    setIsCreateListModalOpen(true);
  };

  const showSuccessToast = () => {
    addToast(
      infoToastConfig("Your changes have been saved successfully.", "success")
    );
  };

  const navigateToCreateListModal = () => {
    setIsCreateListModalOpen(false);
    setIsCreateNewListModalOpen(true);
  };

  const filterRecords = (e) => {
    const filter = e.target.textContent;
    const filtersCopy = { ...filters };
    filtersCopy[filter] = !filtersCopy[filter];
    setFilters(filtersCopy);
  };

  const resetFilters = () => {
    const filtersCopy = { ...filters };
    Object.keys(filtersCopy).forEach((filter) => (filtersCopy[filter] = false));
    setFilters(filtersCopy);
  };

  useEffect(() => {
    const role = sessionStorage.getItem("role");
    if (brand && Object.keys(brand).length) {
      const currentBrands = brand[role]
        .split(",")
        .filter((brand) => brand.trim());
      if (currentBrands.length) {
        const currentBrand =
          searchParams.get("brand")?.toUpperCase() || currentBrands[0];

        if (currentBrand.trim() && currentBrands.includes(currentBrand)) {
          dispatch(SET_BRAND(currentBrand));
          dispatch(
            FETCH_OVERVIEW({
              brand: currentBrand,
              created_by: uuid,
              user_role: role,
            })
          );
        } else {
          setDropdownError(true);
          dispatch(SET_BRAND(currentBrands[0]));
          dispatch(SET_LOADING(false));
          addToast(infoToastConfig("Brand not tagged.", "error"));
        }
        setUserBrands(currentBrands);
      } else {
        dispatch(SET_LOADING(false));
        addToast(infoToastConfig("Brand not tagged.", "error"));
      }
    }
  }, [brand]);

  return (
    <div id="main">
      <section className="section">
        <div className="wrapper overview-page">
          <div>
            <div className="row">
              <div className="col col-12 col-md">
                <div>
                  <div>
                    <div className="row">
                      <div className="col-9">
                        <h2>Overview</h2>
                        {/* Card content  */}
                      </div>
                      {hasAccessToMultipleBrands() ? (
                        <div className="col-3 create-list">
                          <LdsSelect
                            id="select-brand"
                            name="select-brand"
                            label="Select Brand"
                            value={selectedBrand}
                            options={userBrands.map((option) => ({
                              value: option,
                              label: option,
                            }))}
                            onChange={({ value }) => {
                              dispatch(SET_BRAND(value));
                              setDropdownError(false);
                              dispatch(
                                FETCH_OVERVIEW({
                                  brand: value,
                                  created_by: uuid,
                                  user_role: sessionStorage.getItem("role"),
                                })
                              );
                              resetFilters();
                            }}
                            error={dropdownError}
                          />
                        </div>
                      ) : (
                        <h3 className="col-3 create-list">
                          {userBrands.length ? userBrands[0].toUpperCase() : ""}
                        </h3>
                      )}
                    </div>
                    <div
                      className="my-4 d-flex justify-center"
                      style={{ position: "relative" }}
                    >
                      <div className="filter-container">
                        {filtersArray.map((filter, index) => (
                          <LdsBadge
                            className={`${index === 0 ? "" : "ml-4 "}${
                              filters[filter] ? "green-btn" : "blue-btn"
                            } filter`}
                            onClick={filterRecords}
                          >
                            {filter}
                          </LdsBadge>
                        ))}
                      </div>

                      {isAdminRole() ? (
                        <div className="col-3 create-list export-button">
                          <LdsButton
                            classes="button-icon-left blue-btn"
                            icon="DownloadSimple"
                            iconPosition="before"
                            onClick={() =>
                              dispatch(FETCH_EXPORT_SPECIALTY_LIST())
                            }
                          >
                            Export
                          </LdsButton>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            {loading ? (
              <CLoader size={90} />
            ) : (
              <div className="content-block">
                <div className="row">
                  <div className="col col-12 col-md">
                    <LdsCard>
                      <LdsCard.Body>
                        <div className="row">
                          <div className="col-6">
                            <h2>Current</h2>
                          </div>
                          {!isViewerRole() ? (
                            <div className="col-6 create-list">
                              <LdsButton
                                icon="Plus"
                                iconPosition="before"
                                classes="button-icon-left blue-btn"
                                onClick={openCreateNewListModal}
                              >
                                Create new list
                              </LdsButton>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <OverviewTile
                          listRecords={current}
                          isModalOpen={isDeleteModalOpen}
                          setIsModalOpen={setIsDeleteModalOpen}
                          showSuccessToast={showSuccessToast}
                          filters={filters}
                          isCurrent={true}
                        />
                      </LdsCard.Body>
                    </LdsCard>
                  </div>
                </div>
                <div className="row mtop-3">
                  <div className="col col-12 col-md">
                    <LdsCard>
                      <LdsCard.Body>
                        <div className="row">
                          <div className="col-12">
                            <h2>Historical</h2>
                          </div>
                        </div>
                        <OverviewTile
                          listRecords={historical}
                          filters={filters}
                          isCurrent={false}
                        />
                      </LdsCard.Body>
                    </LdsCard>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <CreateNewListModal
          isModalOpen={isCreateNewListModalOpen}
          setIsModalOpen={setIsCreateNewListModalOpen}
          openCreateListModal={openCreateListModal}
        />
        <CreateListModal
          isModalOpen={isCreateListModalOpen}
          setIsModalOpen={setIsCreateListModalOpen}
          modalType={isExistingCreateList}
          navigateBack={navigateToCreateListModal}
        />
      </section>
      <LdsToast />
    </div>
  );
};

export default Overview;
